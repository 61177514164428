import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileCard from '../components/ProfileCard';
import BethelLogo from '../Images/bethelIcon.ico';

const { getProfileCardData } = require("../db/db");
const profileCards = getProfileCardData();

function Main() {
    const navigate = useNavigate();

    // Assuming you want to start with ID 1001 and increment for each profile
    const customIds = profileCards.map((_, index) => 1001 + index);

    const handleCardClick = (customId) => {
        navigate(`/${customId}`);
    };

    return (
        <div className='flex flex-col'>
            <div className='flex flex-row gap-x-2 items-center justify-center pt-8'>
                <img src={BethelLogo} alt='' className='w-8 h-8'></img>
                <h2 className='text-[#a3d902] text-center text-xl text-Mediym'>Team Contacts</h2>
            </div>
            <div className='flex flex-col pt-6'>
                {profileCards.map((proCard, index) => {
                    const customId = customIds[index];
                    return (
                        <div onClick={() => handleCardClick(customId)} key={index}>
                            <ProfileCard proCard={proCard} index={index} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Main;
