// Team
import CEOImage from '../Images/team/CEO.jpg';
import CTOImage from '../Images/team/sanjeewa.jpg';
import TLead from '../Images/team/diyath.jpg';
import Finance from '../Images/team/ruchira.jpg';
import CommunityM from '../Images/team/amal.jpg';
import BDeveloper from '../Images/team/Lakmal.jpg';

// Social Media
import LinkedInImage from '../Images/icons/linkdin.webp'
import TelegramImage from '../Images/icons/telegram1.webp'
import TwitterImage from '../Images/icons/twitter.png'
import UTubeImage from '../Images/icons/youtube.webp'
import FaceBookImage from '../Images/icons/facebook.webp'
import WebImage from '../Images/icons/web.png'

export function getProfileCardData() {
    return [
        { imge: CEOImage, name: "Craig Bricknell", position: "Managing Director/CEO" },
        { imge: CTOImage, name: "Sanjeewa Silva", position: "Director/CTO" },
        { imge: TLead, name: "Diyath Rajapakshe", position: "Head of Engineering" },
        { imge: Finance, name: "Ruchira Perera", position: "Finance Controller" },
        { imge: CommunityM, name: "Amal Chamara", position: "Community Manager" },
        { imge: BDeveloper, name: "Lakmal Bulathge", position: "Blockchain Developer" },
    ];
}

export function getMainProfileData() {
    return [
        { 
            imge: CEOImage, 
            FirstName:"Craige" , 
            LastName:"Bricknell", 
            Designation:"Managing Director/CEO",
            Telephone:"+61 4 0847 2703",
            email:"craig.bricknell@futurecx.com.au",
            Address1:"Level 1/60 Martin Pl,",
            Address2:"Sydney NSW 2000,",
            Address3:"Autralia",
            website:"https://bethelnet.io/",
            linkedinImage:LinkedInImage, 
            twitterImage:TwitterImage, 
            telegramImage:TelegramImage, 
            linkedInLink:"https://www.linkedin.com/in/craig-bricknell-b7198225/",
            twitterLink:"https://x.com/CraigBricknell",
            telegramLink:"https://t.me/Craig_Bricknell"
        },

        { imge: 
            CTOImage, 
            FirstName:"Sanjeewa" , 
            LastName:"Silva", 
            Designation:"Director/CTO",
            Telephone:"+94 71 655 5777",
            email:"sanjeewa.silva@futurecx.com.au",
            Address1:"WUS Experience Center",
            Address2:"No 4 Papiliyana",
            Address3:"Dehiwala Road, Boralesgamuwa",
            website:"https://bethelnet.io/",
            linkedinImage:LinkedInImage, 
            twitterImage:TwitterImage, 
            telegramImage:TelegramImage, 
            linkedInLink:"https://www.linkedin.com/in/sanjeewa-silva/",
            twitterLink:"https://x.com/ssanjeewa",
            telegramLink:"https://t.me/ssanjeewa"
        },

        { imge: TLead, 
            FirstName:"Diyath" ,
             LastName:"Rajapakshe", 
             Designation:"Head of Engineering",
             Telephone:"+94 777 077 838",
             email:"diyath.rajapakshe@futurecx.au",
             Address1:"WUS Experience Center,",
             Address2: "No 4 Papiliyana",
             Address3:"Dehiwala Road, Boralesgamuwa",
             website:"https://bethelnet.io/",
             linkedinImage:LinkedInImage,
             twitterImage:TwitterImage, 
             telegramImage:TelegramImage, 
             linkedInLink:"https://www.linkedin.com/in/diyathsahanrajapakshe/",
             twitterLink:"https://x.com/diyathrajapakse",
             telegramLink:"https://t.me/diyathsahanrajapakshe"
            },

        { imge: 
            Finance, FirstName:"Ruchira" , 
            LastName:"Perera", 
            Designation:"Finance Controller",
            Telephone:"+94 776 586 536",
            email:"ruchira.perera@futurecx.com.au",
            Address1:"WUS Experience Center,",
            Address2: "No 4 Papiliyana",
            Address3:"Dehiwala Road, Boralesgamuwa",
            website:"https://bethelnet.io/",
            linkedinImage:LinkedInImage, 
            twitterImage:TwitterImage,
            telegramImage:TelegramImage, 
            linkedInLink:"https://www.linkedin.com/in/ruchira-perera-4619832a/",
            twitterLink:"https://x.com/RuchiPereraZ",
            telegramLink:"https://t.me/RTPZ229"
        },

        { imge: 
            CommunityM, 
            FirstName:"Amal" , 
            LastName:"Chamara", 
            Designation:"Community Manager",
            Telephone:"+94 763 946 126",
            email:"amal.chamara@futurecx.com.au",
            Address1:"WUS Experience Center,",
             Address2: "No 4 Papiliyana",
             Address3:"Dehiwala Road, Boralesgamuwa",
            website:"https://bethelnet.io/",
            linkedinImage:LinkedInImage, 
            twitterImage:TwitterImage, 
            telegramImage:TelegramImage, 
            linkedInLink:"https://www.linkedin.com/in/amal-chamara-pushpa-kumara-4339a21a2/",
            twitterLink:"https://x.com/amalchamara123",
            telegramLink:"https://t.me/Mydreamdubai"
        },

        { imge: BDeveloper, 
            FirstName:"Lakmal" , 
            LastName:"Bulathge", 
            Designation:"Blockchain Developer",
            Telephone:"+94 776 208 053",
            email:"lakmal.wasantha@futurecx.com.au",
            Address1:"WUS Experience Center,",
             Address2: "No 4 Papiliyana",
             Address3:"Dehiwala Road, Boralesgamuwa",
            website:"https://bethelnet.io/",
            linkedinImage:LinkedInImage, 
            twitterImage:TwitterImage, 
            telegramImage:TelegramImage, 
            linkedInLink:"linkedin.com/in/lakmal-bulathge-6223a213a",
            twitterLink:"",
            telegramLink:"https://t.me/walterlane"
        }


    ];
}

// export function getSocialMediaLinks(){
//     return[

//     ]
// }

export function getCompanySocialLInks(){
    return[
        {LinkedInImage:LinkedInImage, TwitterImage:TwitterImage, TelegramImage:TelegramImage ,YouTube:UTubeImage,FaceBook:FaceBookImage , webImage:WebImage , webLink:"https://bethelnet.io/" , LinkedInLink:"https://www.linkedin.com/company/bethel-blockchain-platform/posts/?feedView=all",TwitterLink:"https://x.com/BethelPlatform",TelegramLink:"https://t.me/bethelplatform",UtubeLink:"https://www.youtube.com/channel/UCVYnUnw_tKTlhdrx5Epnz-w",FaceBookLInk:"https://www.facebook.com/bethelplatform" }
    ]
}
