import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from '../src/pages/main';
import Data from '../src/pages/Data';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App bg-bl">
        <Routes>
          <Route path="/" element={<Main />} />
          {/* Update the path to capture any custom ID */}
          <Route path="/:id" element={<Data />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
