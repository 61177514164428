import React from "react";

function ProfileCard({ proCard }) {
  const { imge, name, position } = proCard;

  return (
    <div className="flex flex-row bg-white/10 rounded-md px-5 py-3 gap-x-5 mx-12 my-2">
      {/* <div className='w-8 h-8 rounded-full'>
                <img src={imge} alt={name} className=''></img>
            </div> */}
      <div class="h-12 w-12 ">
        <img
          class="object-cover h-12 w-12 rounded-full z-20"
          src={imge}
          alt={name}
        />
      </div>
      <div className="flex flex-col">
        <h1 className="text-white/85 font-medium">{name}</h1>
        <h1 className="text-[#2cffae]/50 text-sm">{position}</h1>
      </div>
    </div>
  );
}

export default ProfileCard;
