// import React from 'react';
// import { useParams } from 'react-router-dom';
// import MainProfileCard from '../components/MainProfileCard';

// const { getMainProfileData, getSocialMediaLinks } = require("../db/db");
// const MainProfileDa = getMainProfileData();

// function Data() {
//   const { id } = useParams(); 
//   const profileData = getMainProfileData()[index]; 
//   const index = parseInt(id) - 1001;

//   if(index < 0 || index >= MainProfileDa.length){
//     return <div>Profile not found</div>
//   }

 

//   return (
//     <div className=''>
//       <MainProfileCard mainProData={profileData} />
//     </div>
//   );
// }

// export default Data;

import React from 'react';
import { useParams } from 'react-router-dom';
import MainProfileCard from '../components/MainProfileCard';

const { getMainProfileData } = require("../db/db");
const MainProfileDa = getMainProfileData();

function Data() {
  const { id } = useParams(); // Get the custom ID from the URL
  const index = parseInt(id) - 1001; // Convert the custom ID to the corresponding index
  
  // Check if the index is valid before accessing the profile data
  if (index < 0 || index >= MainProfileDa.length) {
    return <div>Profile not found</div>;
  }

  const profileData = MainProfileDa[index]; // Fetch the specific profile data

  return (
    <div>
      <MainProfileCard mainProData={profileData} />
    </div>
  );
}

export default Data;
